import { parseRichText } from "../../utils/parseRichText";

const getFaqMeta = (accordionItems, schemaName) => {
  const Accordion = accordionItems.map(faq => ({
    '@type': 'Question',
    name: faq.question || 'Question',
    text: faq.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: parseRichText(faq.answer)
    },
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    name: schemaName || 'Frequently Asked Questions',
    mainEntity: Accordion,
  };
};

export default getFaqMeta;
